


import React, { useState } from 'react';
import './Home3.css';
import Title from '../Title/Title';
import white_arrow from '../../assets/gallery/white-arrow.png';


import gallery_1 from '../../assets/dennis-images/Slide1.PNG';

import gallery_01 from '../../assets/dennis-images/Slide1.PNG';
import gallery_02 from '../../assets/dennis-images/Slide2.PNG';
import gallery_03 from '../../assets/dennis-images/Slide3.PNG';
import gallery_04 from '../../assets/dennis-images/Slide4.PNG';
// import gallery_04 from '../../assets/dennis-images/Slide1.PNG';
// import gallery_06 from '../../assets/dennis-images/Slide1.PNG';

import gallery_11 from '../../assets/dennis-images/Slide5.PNG';
import gallery_12 from '../../assets/dennis-images/Slide6.PNG';
import gallery_13 from '../../assets/dennis-images/Slide7.PNG';
import gallery_14 from '../../assets/dennis-images/Slide8.PNG';

import gallery_21 from '../../assets/dennis-images/Slide9.PNG';
import gallery_22 from '../../assets/dennis-images/Slide10.PNG';
import gallery_23 from '../../assets/dennis-images/Slide11.PNG';
import gallery_24 from '../../assets/dennis-images/Slide12.PNG';


const Home3 = () => {
  const [seeMore, setSeeMore] = useState(false);
  const buttonTitle = seeMore ? "Show less pictures" : "Show more pictures";

  return (
    <div className=''>
      {/* <div className=""> */}
        <Title title={"Programs"}/>
      {/* </div> */}
      <div className="gallery">
        <figure>
          <img src={gallery_01} alt="Description for Image 1" />
          <figcaption>Participants at boys bootcamp 2024</figcaption>
        </figure>
        <figure>
          <img src={gallery_02} alt="Description for Image 2" />
          <figcaption>Awarding certificate of honor at Ghana to Worship - 2024</figcaption>
        </figure>
        <figure>
          <img src={gallery_03} alt="Description for Image 3" />
          <figcaption>Dissection section with GCISHS students</figcaption>
        </figure>
        <figure>
          <img src={gallery_04} alt="Description for Image 4" />
          <figcaption>Group picture with Ghana to Worship organizers - 2022</figcaption>
        </figure>
      </div>

      {seeMore && (
        <>
          <div className="gallery">
            <figure>
              <img src={gallery_11} alt="Description for Image 5" />
              <figcaption>Photo with model united nations delegates</figcaption>
            </figure>
            <figure>
              <img src={gallery_12} alt="Description for Image 6" />
              <figcaption>Best position paper award</figcaption>
            </figure>
            <figure>
              <img src={gallery_13} alt="Description for Image 7" />
              <figcaption>A photo with NACCA officials</figcaption>
            </figure>
            <figure>
              <img src={gallery_14} alt="Description for Image 8" />
              <figcaption>Worshipping at SHABBACH - 2018</figcaption>
            </figure>
          </div>
          <div className="gallery">
            <figure>
              <img src={gallery_21} alt="Description for Image 9" />
              <figcaption>Photo with Elder Kelvin Amos Annan at boy bootcamp - 2024</figcaption>
            </figure>
            <figure>
              <img src={gallery_22} alt="Description for Image 10" />
              <figcaption>Boy bootcamp</figcaption>
            </figure>
            <figure>
              <img src={gallery_23} alt="Description for Image 11" />
              <figcaption>Retreat at Pentecost conversion center (PCC)</figcaption>
            </figure>
            <figure>
              <img src={gallery_24} alt="Description for Image 12" />
              <figcaption>Photo with the students at Best Brain International School  </figcaption>
            </figure>
          </div>
        </>
      )}

      <button onClick={() => { setSeeMore(!seeMore) }} className='btn dark-btn more'>
        {buttonTitle}
        {/* <img src={white_arrow} alt="" /> */}
      </button>
    </div>
  );
};

export default Home3;

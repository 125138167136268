import React from 'react'
import "./AboutUs.css"

// import program_1 from "../../assets/program-1.png"
// import program_2 from "../../assets/program-2.png"
// import program_3 from "../../assets/program-3.png"

import program_1 from "../../assets/publication/abst1.png"
import program_2 from "../../assets/publication/abst2.png"
import program_3 from "../../assets/publication/abst3.png"

import profile_eduzolve from "../../assets/aboutus/eduzolve.png"
import profile_weta from "../../assets/aboutus/weta.png"
import profile_pta from "../../assets/aboutus/pta.png"

import profile_peter_2 from "../../assets/aboutus/peter.png"


import program_icon_1 from "../../assets/publication/program-icon-1.png"
import program_icon_2 from "../../assets/publication/program-icon-2.png"
import program_icon_3 from "../../assets/publication/program-icon-3.png"


const AboutUs2 = () => {
  return (
    <div className='programs'>

        <div className="program">
            <a href="#" >
                <img src={profile_eduzolve} alt="" />
                <div className='description'>
                    <h2>EduZolve Oy</h2>
   
                    <p>
                        EduZolve is a Finland-based company that provides high-quality training and education services to 
                        individuals and organizations. EduZolve also provides services and platforms that enables West Africa
                        students to effectively and efficiency prepare for their examinations. 
                    </p>
                </div>
                <div className="caption">
                    <img src={program_icon_1} alt="" />
                    <p>EduZolve Oy</p>
                </div>
            </a>
        </div>

        {/* <div className="program">
            <a href="#" >
                <img src={profile_weta} alt="" />
                <div className='description'>
                    <h2>Weta Traditional Chieftancy</h2>
           
                    <p>
                        With the support of the Weta Traditional Chieftancy led by Torgbuiga Akpo Ashiakpor VI, 
                        we are working to build the schools in Weta Community. 

                    </p>
                </div>
                <div className="caption">
                    <img src={program_icon_2} alt="" />
                    <p>Weta Traditional Chieftancy</p>
                </div>
            </a>
        </div> */}

        <div className="program">
                <img src={profile_pta} alt="" />
                <div className='description'>
                    <h2>Gifty-Peter Foundation</h2>
           
                    <p>
                    Our primary aim is to implement programs that will help students develop their reading habits, computer skills, and overall academic prowess.
                    </p>
                </div>
                <div className="caption">
                <img src={program_icon_3} alt="" />
                    <p>Teachers and Parents Association - Weta Community</p>
                </div>
        </div>

    </div>
  )
}

export default AboutUs2







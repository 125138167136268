import React from 'react'
import "./AboutUs.css"


import profile_gifty_3 from "../../assets/aboutus/gifty.png"
import profile_gifty_1 from "../../assets/aboutus/dennis.png"
import profile_peter_2 from "../../assets/aboutus/peter.png"
import program_3 from "../../assets/publication/abst3.png"


import program_icon_1 from "../../assets/publication/program-icon-1.png"
import program_icon_2 from "../../assets/publication/program-icon-2.png"
import program_icon_3 from "../../assets/publication/program-icon-3.png"


const AboutUs1 = () => {
  return (
    <div className='programs'>

        <div className="program">
            {/* <a href="https://pubs.acs.org/doi/full/10.1021/acsomega.8b01878" target='_blank'> */}
                <img src={profile_gifty_1} alt="" />
                <div className='description'>
                    {/* <h3>ACS Omega, 2018, 3</h3> */}
                    <h2>Dennis Blemano Nartey</h2>
                    <h4>HUMAN BIOLOGIST AND FORENSIC SCIENTIST</h4>
                    {/* <p>
                    An optimistic, enthusiastic, and knowledgeable registered general nurse in the United Kingdom with 
                    hands-on work experience and commitment to helping others, especially young students. 
                    Gifty and Peter decided to launch Gifty-Peter Foundation, a scholarship and 
                    support program, to help the students of Weta community. As Gifty is committed to 
                    safeguarding and promoting the welfare of people, she wants to harness these set of 
                    skills to help build and promote the educational system of her community. 
                    </p> */}
                </div>
                <div className="caption">
                    {/* <img src={program_icon_1} alt="" /> */}
                    <p>Dennis Blemano Nartey</p>
                </div>
            {/* </a> */}
        </div>


        <div className="program">
            {/* <a href="https://www.sciencedirect.com/science/article/abs/pii/S003960282030248X#fig0012" target='_blank'> */}
             
                <div className='description'>
                    <p>
                        I am a tutor of Biology and Mathematics with nearly 15 years
                        of teaching experience under my belt. The ability to concisely
                        explain theoretical principles and concepts to unveil the
                        practicality buried in it in order to deepen the understanding of
                        my students is my greatest asset. My commitment to fostering
                        a rich learning environment and advancing knowledge
                        continues to drive my professional endeavours. My journey in
                        education has been fueled by a deep-rooted passion to inspire
                        Christ-kind of integrity into young people as future leaders.
                        Currently, I am dedicated to teaching both O-level and A-level
                        Cambridge Biology, as well as West African Senior Secondary
                        Certificate Examination (WASSCE) Biology at Ghana
                        Christian International High School (GCIHS).
                        I double as a science researcher with at least a co-authored
                        publication under my sleeve. I have a proven track record exhibiting a high level of
                        professionalism and administrative skills as well as a firm sense of responsibility. I am also a
                        Mathematics and Science Olympiad as well as Model United Nations (MUN) coach striving to
                        inspire and churn out a generation of problem-solvers and innovators.
                    </p>
                </div>
                <div className="caption">
                    {/* <img src={program_icon_2} alt="" /> */}
                    <p>PROFILE</p>
                </div>
            {/* </a> */}
        </div>



    </div>
  )
}

export default AboutUs1






